.contact-section-two {
  //   border: 2px solid red;
  padding: 132px 10px 0px;

  & .content {
    max-width: var(--max-width);
    // border: 2px solid blue;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    & .heading {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 46px;
      text-align: center;
      color: #000000;

      @media (max-width: $breakpoint-large) {
        font-size: 28px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 25px;
      }
    }

    & .top-text {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #000000;
      max-width: 932px;

      @media (max-width: $breakpoint-large) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 15px;
      }
    }

    & .form-container {
      // border: 2px solid green;
      margin-top: 40px;
      width: 100%;
      display: flex;
      gap: 50px;
      justify-content: center;

      @media (max-width: $breakpoint-large) {
        flex-wrap: wrap;
      }
      @media (max-width: $breakpoint-tablet) {
      }

      & form {
        box-shadow: 4px 4px 26px rgba(189, 189, 189, 0.25);
        border-radius: 15px;
        padding: 70px;
        display: flex;
        flex-direction: column;
        gap: 21px;
        // border: 2px solid red;

        @media (max-width: $breakpoint-large) {
          padding: 50px;
          max-width: 100%;
          // justify-content: center;
          align-items: center;
        }
        @media (max-width: $breakpoint-tablet) {
          padding: 30px;
        }

        & h1 {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 46px;
          color: #000000;

          @media (max-width: $breakpoint-large) {
            font-size: 22px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 20px;
          }
        }

        & .form-item {
          display: flex;
          flex-direction: column;
          gap: 10px;

          @media (max-width: $breakpoint-large) {
            max-width: 90%;
          }
          @media (max-width: $breakpoint-tablet) {
          }

          & label {
            font-family: "Century Gothic";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            text-align: justify;
            color: #000000;

            @media (max-width: $breakpoint-large) {
              font-size: 14px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
          }

          & input {
            background: #f3f3f3;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            height: 49px;
            width: 479px;
            padding: 10px;
            font-size: 16px;

            @media (max-width: $breakpoint-large) {
              max-width: 100%;
              font-size: 14px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
          }

          & textarea {
            width: 479px;
            height: 236px;
            resize: none;
            background: #f3f3f3;
            font-size: 16px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;

            @media (max-width: $breakpoint-large) {
              max-width: 100%;
              font-size: 14px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
          }
        }

        & .button {
          // height: 49px;
          min-width: 90%;
          // width: 479px;
        }

        & button {
          width: 136px;
          height: 59px;
          background: #ad832d;
          border: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          text-align: center;
          color: white;
          text-transform: capitalize;
          cursor: pointer;

          @media (max-width: $breakpoint-large) {
            font-size: 18px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }
      }

      & .info {
        box-shadow: 4px 4px 26px rgba(189, 189, 189, 0.25);
        border-radius: 15px;
        padding: 70px;
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        // gap: 30px;

        & h1 {
          color: var(--dark, #000);
          font-family: Century Gothic;
          font-size: 24px;
          font-style: normal;
          font-weight: 400;
          line-height: 40px;
        }

        & .details {
          margin-top: 30px;
          display: flex;
          flex-direction: column;
          gap: 70px;

          & .detail-item {
            display: flex;
            gap: 30px;
            align-items: center;

            & img {
              width: 26.833px;
              height: 35px;
            }

            & .text {
              display: flex;
              flex-direction: column;
              gap: 5px;

              & p {
                font-family: "Century Gothic";
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 28px;
                text-align: justify;
                color: #000000;

                @media (max-width: $breakpoint-large) {
                  font-size: 18px;
                }
                @media (max-width: $breakpoint-tablet) {
                  font-size: 16px;
                }
              }
            }
          }
        }

        & h2 {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 46px;
          color: #000000;
          text-transform: capitalize;
          margin-top: 80px;

          @media (max-width: $breakpoint-large) {
            font-size: 30px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 26px;
          }
        }

        & .socials {
          margin-top: 30px;
          display: flex;
          gap: 40px;

          & .social-item {
            cursor: pointer;
            width: 40px;
            height: 40px;

            & img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
    }
  }
}
