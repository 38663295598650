.prenav {
  //   border: 2px solid red;
  background: #52658d;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 41px;

  @media (max-width: $breakpoint-tablet) {
    display: none;
  }

  & .content {
    // border: 2px solid blue;
    width: 80%;
    max-width: 1140px;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    @media (max-width: $breakpoint-large) {
      justify-content: flex-end;
    }
  }

  & .left {
    display: flex;
    gap: 17.5px;

    @media (max-width: $breakpoint-large) {
      display: none;
      justify-items: flex-end;
    }
    @media (max-width: $breakpoint-tablet) {
    }

    & .item {
      display: flex;
      gap: 8.5px;

      & p {
        font-family: "Heebo";
        font-style: normal;
        font-weight: 300;
        font-size: 14px;
        line-height: 21px;
        color: #ffffff;
      }
    }
  }

  & .right {
    display: flex;
    gap: 31px;
  }
}

.navbar {
  background: white;
  // border: 2px solid red;
  display: flex;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15);
  position: sticky;
  top: 0px;
  left: 0;
  justify-content: center;
  z-index: 9999;
  // position: relative;
  // width: 50%;
  height: 114px;
  width: 100%;

  @media (max-width: $breakpoint-tablet) {
    // position: fixed;
    // top: 20px;
    // left: 0;
    // height: 100%;
  }

  .nav-center {
    display: flex;
    width: 80%;
    max-width: 1140px;
    // margin: 0 auto;
    justify-content: space-between;
    gap: 20px;
    padding: 37px 0;
    // border: 2px solid blue;
    height: 100%;
    // position: sticky;
    // top: 0;
    // left: 0;
    // border: 2px solid red;

    @media (max-width: $breakpoint-large) {
      width: 100%;
      // padding: 37px 40px;
    }

    & .left {
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: center;
      text-decoration: none;
      width: fit-content;

      @media (max-width: $breakpoint-large) {
        // font-size: 20px;
        width: 70%;
        // border: 2px solid red;
        justify-content: flex-start;
        margin: 0 auto;
      }

      & h1 {
        font-family: "Neris";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 21px;
        text-align: center;
        color: #000000;

        @media (max-width: $breakpoint-large) {
          font-size: 20px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 16px;
        }
      }
    }

    & .right {
      display: flex;
      gap: 60px;
      align-items: center;
      transition: var(--transition);

      @media (max-width: $breakpoint-large) {
        // display: none;
        position: absolute;
        top: 100%;
        z-index: 3;
        flex-direction: column;
        background: white;
        width: 100%;
        align-items: flex-start;
        height: 0;
        padding-bottom: 30px;
        transform: translateY(-100%);
        transition: var(--transition);
      }

      & .item {
        cursor: pointer;
        text-decoration: none;
        transition: var(--transition);

        @media (max-width: $breakpoint-large) {
          width: 88%;
          margin: 0 auto;
          display: none;
        }
      }

      & p {
        font-family: "Neris";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #000000;

        @media (max-width: $breakpoint-large) {
          font-size: 14px;
          text-align: left;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
      }

      & .active {
        p {
          color: #ad832d;
        }
      }

      & .phone {
        display: flex;
        gap: 10px;

        @media (max-width: $breakpoint-large) {
          display: none;
        }

        & p {
          font-family: "Neris";
          font-style: normal;
          font-weight: 300;
          font-size: 15px;
          line-height: 18px;
          text-align: right;
          color: #6b7c93;

          @media (max-width: $breakpoint-large) {
            font-size: 13px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 11px;
          }
        }

        & h3 {
          font-family: "Neris";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          display: flex;
          align-items: center;
          text-align: right;

          color: #6b7c93;

          @media (max-width: $breakpoint-large) {
            font-size: 14px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 12px;
          }
        }
      }
    }

    & .menu {
      display: none;

      @media (max-width: $breakpoint-large) {
        display: flex;
        cursor: pointer;
        margin-right: 40px;
      }
    }
  }

  & .menu-active {
    // border: 2px solid green;
    & .right {
      transition: var(--transition);
      @media (max-width: $breakpoint-large) {
        height: fit-content;
        transition: var(--transition);
        transform: translateY(0);
      }
      & .item {
        @media (max-width: $breakpoint-large) {
          display: block;
        }
      }

      & .phone {
        @media (max-width: $breakpoint-large) {
          display: none;
        }
      }
    }
  }
}
