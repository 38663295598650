.footer {
  height: 440px;
  background: #f8faff;
  margin-top: 154px;
  display: flex;
  align-items: center;
  // border: 2px solid blue;

  @media (max-width: $breakpoint-large) {
    margin-top: 124px;
    padding: 18.3rem 0;
  }
  @media (max-width: $breakpoint-tablet) {
    margin-top: 74px;
  }

  & .content {
    display: flex;
    gap: 90px;
    width: 80%;
    max-width: var(--max-width);
    margin: 0 auto;
    // border: 2px solid red;
    // grid-template-columns: 1fr 1fr 1fr 1fr;

    @media (max-width: $breakpoint-large) {
      flex-direction: column;
      gap: 70px;
    }
    @media (max-width: $breakpoint-tablet) {
      gap: 40px;
    }

    & .item {
      display: flex;
      flex-direction: column;
      gap: 20px;

      & h2 {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        align-items: center;
        color: #ad832d;
        text-transform: capitalize;
        min-width: 133px;

        @media (max-width: $breakpoint-large) {
          font-size: 20px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 18px;
        }
      }

      & p {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        align-items: center;
        color: #000000;
        text-transform: capitalize;
        // cursor: pointer;
        width: fit-content;
        text-decoration: none;

        @media (max-width: $breakpoint-large) {
          font-size: 18px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 15px;
        }
      }
    }

    .logo,
    .contact {
      @media (max-width: $breakpoint-large) {
        display: none;
      }
      @media (max-width: $breakpoint-tablet) {
      }
    }

    & .contact {
      & .contact-item {
        display: flex;
        gap: 20px;

        & img {
          width: 24px;
          height: 34.29px;
        }

        & p {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          align-items: center;
          color: #000000;
        }
      }
    }

    & .links {
      & .links-item {
        text-decoration: none;
        color: var(--dark, #000);
        text-transform: capitalize;
        cursor: pointer;
      }
    }

    & .touch {
      & .input {
        display: flex;

        & input,
        button {
          border: none;
        }

        & input::placeholder {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 300;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.5;
        }

        & button {
          background: #00ab6b;
          padding: 11px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
        & input {
          padding: 10px 25px;
        }
      }

      & .socials {
        display: flex;
        gap: 20px;

        & .social-item {
          cursor: pointer;
        }
      }

      & .contact-touch {
        display: none;

        @media (max-width: $breakpoint-large) {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}
