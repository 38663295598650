.services-section-one {
  // border: 2px solid red;
  height: 70vh;
  max-height: 753px;
  position: relative;
  display: flex;
  align-items: center;
  background-repeat: no-repeat;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #52658d;
    opacity: 0.55;
  }
  & .content {
    z-index: 2;
    position: relative;
    // border: 2px solid blue;
    margin: 0 auto;
    max-width: var(--max-width);
    justify-content: center;
    width: 80%;
    // align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px;

    & h1 {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 46px;
      color: #ffffff;

      @media (max-width: $breakpoint-large) {
        font-size: 30px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 28px;
      }
    }

    & p {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      color: #ffffff;
      max-width: 523px;

      @media (max-width: $breakpoint-large) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 15px;
      }
    }
  }
}

.services-section-two {
  padding: 64px 0;

  & .content {
    width: var(--max-width);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 120px;
    width: 80%;
    // border: 2px solid red;

    & .item {
      display: flex;
      gap: 75px;

      @media (max-width: $breakpoint-large) {
        flex-direction: column;
      }
      @media (max-width: $breakpoint-tablet) {
      }

      & .left {
        display: flex;
        flex-direction: column;
        gap: 20px;
        min-width: 40%;
        // max-width: 45%;
        max-width: 25.25rem;

        @media (max-width: $breakpoint-large) {
          min-width: 100%;
        }

        & h2 {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          color: #ad832d;
        }

        & p {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          text-align: justify;
          color: #000000;
        }
      }

      & .right {
        & img {
          max-width: 100%;
          max-height: 100%;
          width: 40rem;
          height: 26.6875rem;

          @media (max-width: $breakpoint-large) {
            width: 100%;
            height: 100%;
          }
          @media (max-width: $breakpoint-tablet) {
          }
        }
      }
    }

    & .left-item {
      & .right {
        position: relative;
        transform: translateY(45px);
        // border: 2px solid blue;
        &::before {
          content: "";
          position: absolute;
          width: 90px;
          height: 90px;
          background: #ad832d;
          top: 45px;
          right: 45px;
          transform: translate(100%, -100%);

          @media (max-width: $breakpoint-large) {
            width: 70px;
            height: 70px;
            top: 35px;
            right: 35px;
          }
          @media (max-width: $breakpoint-tablet) {
            width: 50px;
            height: 50px;
            top: 25px;
            right: 25px;
          }
        }

        & img {
          position: relative;
          z-index: 2;
        }
      }
    }
    & .right-item {
      @media (max-width: $breakpoint-large) {
        flex-direction: column-reverse;
      }
      & .right {
        position: relative;
        // border: 2px solid blue;
        &::before {
          content: "";
          position: absolute;
          width: 90px;
          height: 90px;
          background: #52658d;
          bottom: 45px;
          right: 45px;
          transform: translate(100%, 100%);

          @media (max-width: $breakpoint-large) {
            width: 70px;
            height: 70px;
            bottom: 45px;
            right: 35px;
          }
          @media (max-width: $breakpoint-tablet) {
            width: 50px;
            height: 50px;
            bottom: 35px;
            right: 25px;
          }
        }

        & img {
          position: relative;
          z-index: 2;
        }
      }
    }
  }
}
