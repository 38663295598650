.error {
  & .center {
    // margin-top: 90px;
    // border: 2px solid red;
    max-width: var(--max-width);
    margin: 60px auto;

    // max-height: 80vh;

    & a {
      cursor: pointer;
      text-decoration: none;
      font-size: 16px;
      font-weight: 700;
      color: white;
      background: #52658d;
      padding: 15px 20px;
      border-radius: 10px;
    }

    & img {
      width: 100%;
      height: 100%;
      margin: 50px;
    }
  }
}
