.section-container {
  overflow-x: hidden;
  // width: 100vw;
  position: relative;

  @media (max-width: $breakpoint-large) {
  }
  @media (max-width: $breakpoint-tablet) {
  }

  & .left-arrow {
    position: absolute;
    top: 50%;
    z-index: 2;
    left: 80px;
    transform: translateY(-50%);
    cursor: pointer;

    @media (max-width: $breakpoint-large) {
      left: 40px;
      & img {
        height: 30px;
        width: 30px;
      }
    }
    @media (max-width: $breakpoint-tablet) {
      transition: var(--transition);
      left: 20px;
      & img {
        height: 20px;
        width: 20px;
      }
    }
  }

  & .right-arrow {
    position: absolute;
    top: 50%;
    right: 80px;
    z-index: 2;
    transform: translateY(-50%);
    cursor: pointer;

    @media (max-width: $breakpoint-tablet) {
      transition: var(--transition);
      right: 20px;
      & img {
        height: 20px;
        width: 20px;
      }
    }
  }
  & .section-content {
    display: flex;
    // width: 100vw;
    transition: var(--transition);
  }
}

.section-one {
  height: 70vh;
  max-height: 753px;
  min-width: 100vw;
  position: relative;
  // border: 2px solid red;
  background-repeat: no-repeat;
  position: relative;
  background-size: cover;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (max-width: $breakpoint-large) {
    height: 35rem;
    background-size: 100% 35rem;
    // background-position: 0px 20px;
  }

  &::before {
    content: "";
    background: #52658d;
    opacity: 0.55;
    height: 70vh;
    max-height: 753px;
    width: 100%;
    position: absolute;
    z-index: 2;
  }

  & .content {
    z-index: 3;
    position: relative;
    width: 80%;
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    & h1 {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 46px;
      /* or 128% */
      max-width: 426px;

      display: flex;
      align-items: center;

      color: #ffffff;

      @media (max-width: $breakpoint-large) {
        transition: var(--transition);
        font-size: 30px;
      }
      @media (max-width: $breakpoint-tablet) {
        // font-size: 25px;
        max-width: 19.9rem;
        font-size: 1.68825rem;
        // margin: 0 auto;
      }
    }

    p {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;

      color: #ffffff;

      @media (max-width: $breakpoint-large) {
        transition: var(--transition);
        // font-size: 30px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 0.9rem;
      }
    }

    & button {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      //   padding: 20px 40.11px 20px 41px;
      background: #ad832d;
      border-radius: 3px;
      border: none;
      width: 178.11px;
      height: 56px;
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 1.2px;
      text-transform: uppercase;
      cursor: pointer;
      color: #ffffff;
      transition: var(--transition);

      @media (max-width: $breakpoint-tablet) {
        font-size: 0.7rem;
        width: 8.91538rem;
        height: 2.62619rem;
      }

      &:hover {
        color: #ad832d;
        background: white;
        transition: var(--transition);
      }
    }
  }
}

.section-two {
  // border: 2px solid red;

  & .content {
    width: 80%;
    max-width: var(--max-width);
    margin: 0 auto;
    display: flex;
    gap: 41px;
    // border: 2px solid blue;
    align-items: center;

    @media (max-width: $breakpoint-large) {
      flex-direction: column;
      padding-top: 3.12rem;
      width: 100%;
    }
    @media (max-width: $breakpoint-tablet) {
    }

    & p {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: justify;
      color: #000000;
      // border: 2px solid red;

      @media (max-width: $breakpoint-large) {
        font-size: 18px;
        margin: 0 3rem;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 15px;
      }
    }

    & img {
      @media (max-width: $breakpoint-tablet) {
        // height: 478.5rem;
        height: 32.25988rem;
        width: 100%;
      }
    }
  }
}

.section-three {
  // display: flex;
  padding: 105px 40px;
  background: #f0f5ff;

  & .content {
    width: 80%;
    max-width: var(--max-width);
    margin: 0 auto;
    // border: 2px solid blue;
    display: flex;
    gap: 92px;
    align-items: center;

    @media (max-width: $breakpoint-large) {
      flex-direction: column;
      gap: 62px;
    }
    @media (max-width: $breakpoint-tablet) {
      gap: 32px;
    }

    & .left {
      display: flex;
      flex-direction: column;
      gap: 31px;
      min-width: 228px;

      @media (max-width: $breakpoint-large) {
        align-items: center;
        gap: 25px;
      }
      @media (max-width: $breakpoint-tablet) {
        gap: 15px;
      }

      & h2 {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 600;
        font-size: 36px;
        line-height: 46px;
        display: flex;
        align-items: center;
        color: #000000;

        @media (max-width: $breakpoint-large) {
          font-size: 30px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 25px;
        }
      }

      & p {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        display: flex;
        align-items: center;
        color: #000000;

        @media (max-width: $breakpoint-large) {
          font-size: 14px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
        }
      }

      & button {
        border: none;
        cursor: pointer;
        background: #ad832d;
        border-radius: 3px;
        padding: 20px;
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: #ffffff;
        max-width: 190px;

        @media (max-width: $breakpoint-large) {
          font-size: 14px;
          padding: 18px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 12px;
          padding: 16px;
        }

        &:hover {
          color: #ad832d;
          background: white;
          transition: var(--transition);
        }
      }
    }

    & .right {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 50px;

      @media (max-width: $breakpoint-large) {
        grid-template-columns: 1fr;
      }

      & .item {
        // border: 2px solid blue;
        padding: 20px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        gap: 10px;
        flex-direction: column;
        background: #ffffff;
        box-shadow: 0px 8px 4px rgba(189, 186, 186, 0.25);
        border-radius: 10px;
        min-width: 403px;
        max-height: 250px;

        & h3 {
          text-align: center;
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #ad832d;

          @media (max-width: $breakpoint-large) {
            font-size: 20px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 18px;
          }
        }

        & p {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          display: flex;
          align-items: center;
          text-align: center;
          color: #000000;

          @media (max-width: $breakpoint-large) {
            font-size: 18px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }
      }
    }
  }
}

.section-four {
  height: 388px;
  background-size: cover;
  // border: 2px solid red;
  // width: 100%;
  display: flex;
  // align-items: center;
  // justify-content: c;
  position: relative;

  @media (max-width: $breakpoint-large) {
    height: 10.1875rem;
  }
  &::before {
    content: "";
    width: 100%;
    height: 100%;
    background: #ad832d;
    opacity: 0.7;
    position: absolute;
  }
  & .content {
    width: 80%;
    max-width: var(--max-width);
    margin: 0 auto;
    // border: 2px solid blue;
    padding: 70px 94px;
    background: #ffffff;
    box-shadow: 0px 8px 4px rgba(236, 234, 234, 0.25);
    border-radius: 10px;
    transform: translateY(158px);
    display: flex;
    flex-direction: column;
    gap: 20px;

    @media (max-width: $breakpoint-large) {
      gap: 15px;
      padding: 50px 74px;
      transform: translateY(68px);
      height: fit-content;
    }
    @media (max-width: $breakpoint-tablet) {
      gap: 10px;
      // padding: 30px 54px;
      padding: 2rem;
    }

    & h2 {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 46px;
      text-align: center;
      color: #52658d;

      @media (max-width: $breakpoint-large) {
        line-height: 36px;
        font-size: 28px;
      }
      @media (max-width: $breakpoint-tablet) {
        line-height: 26px;
        font-size: 25px;
      }
    }

    & p {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #000000;

      @media (max-width: $breakpoint-large) {
        font-size: 18px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 16px;
      }
    }
  }
}

.section-five {
  // border: 2px solid red;
  padding-top: 257px;

  @media (max-width: $breakpoint-large) {
    padding-top: 107px;
  }
  @media (max-width: $breakpoint-tablet) {
    padding-top: 57px;
  }

  & .center {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  & .content {
    margin: 0 auto;
    width: 80%;
    max-width: var(--max-width);
    // border: 2px solid blue;
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;

    @media (max-width: $breakpoint-tablet) {
      padding-top: 30rem;
    }
    & .item {
      display: flex;
      // justify-content: space-between;
      gap: 233px;

      @media (max-width: $breakpoint-large) {
        flex-direction: column-reverse;
        gap: 30px;
        align-items: center;
      }
      @media (max-width: $breakpoint-tablet) {
        // padding-top: 32rem;
      }

      & .left {
        display: flex;
        flex-direction: column;
        gap: 10px;
        max-width: 412px;

        & h3 {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 32px;
          display: flex;
          align-items: center;
          color: #ad832d;

          @media (max-width: $breakpoint-large) {
            font-size: 22px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 20px;
          }
        }

        & p {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          text-align: justify;
          color: #000000;

          @media (max-width: $breakpoint-large) {
            font-size: 18px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 16px;
          }
        }
      }

      & .right {
        background: var(--primary, #52658d);
        height: 294px;
        width: 469px;
        display: flex;
        justify-content: flex-end;
        & img {
          width: 435px;
          height: 100%;
        }
      }
    }

    & .right-item {
      @media (max-width: $breakpoint-large) {
        flex-direction: column;
      }

      & .right {
        background: var(--secondary, #ad832d);
      }
    }
  }

  & .bottom-text {
    font-family: "Century Gothic";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    text-align: center;
    color: #000000;
    max-width: 954px;
    margin: 0 auto;

    @media (max-width: $breakpoint-large) {
      width: 80%;
      font-size: 18px;
      text-align: justify;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 16px;
    }
  }

  & .vision {
    margin: 0 auto;
    width: 80%;
    max-width: var(--max-width);
    display: flex;
    gap: 50px;
    margin-top: 150px;

    @media (max-width: $breakpoint-large) {
      margin-top: 120px;
      flex-direction: column;
    }
    @media (max-width: $breakpoint-tablet) {
      width: 100%;
      margin-top: 50px;
    }

    & .vision-right {
      background: #52658d;
      padding: 50px;
      display: flex;
      flex-direction: column;
      gap: 20px;
      // align-items: center;
      justify-content: center;

      & h2 {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 700;
        font-size: 30px;
        line-height: 46px;
        color: #ffffff;

        @media (max-width: $breakpoint-large) {
          font-size: 30px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 25px;
          text-align: center;
        }
      }

      & p {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        text-align: justify;
        color: #ffffff;

        @media (max-width: $breakpoint-large) {
          font-size: 18px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 15px;
        }
      }
    }
  }
}
