@import url("https://fonts.googleapis.com/css2?family=Heebo:wght@100;200;300;400;500;600;700;800;900&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
*,
body,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Century Gothic";
  scroll-behavior: smooth;
  /* overflow-x: hidden; */
}

:root {
  --max-width: 1140px;
  --transition: all 0.2s linear;
}

@font-face {
  font-family: Neris;
  src: url("../assets/fonts/Neris-Light.otf") format("opentype"),
    url("../assets/fonts/Neris-LightItalic.otf") format("opentype"),
    url("../assets/fonts/Neris-SemiBold.otf") format("opentype"),
    url("../assets/fonts/Neris-SemiBoldItalic.otf") format("opentype"),
    url("../assets/fonts/Neris-Thin.otf") format("opentype"),
    url("../assets/fonts/Neris-ThinItalic.otf") format("opentype");
}
@font-face {
  font-family: "Century Gothic";
  src: url("../assets/fonts/Century\ Gothic.ttf") format("truetype"),
    url("../assets/fonts/CenturyGothic.ttf") format("truetype"),
    url("../assets/fonts/GOTHIC.ttf") format("truetype"),
    url("../assets/fonts/GOTHICB.ttf") format("truetype"),
    url("../assets/fonts/GOTHICB0.ttf") format("truetype"),
    url("../assets/fonts/GOTHICBI.ttf") format("truetype"),
    url("../assets/fonts/GOTHICI.ttf") format("truetype");
}
