.about-section-one {
  position: relative;

  & .content {
    margin: 0 auto;
    // display: flex;

    & .left {
      position: relative;
      display: flex;

      & .image {
        max-width: 1301px;
        width: 80%;
        position: relative;
        height: 753px;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
          height: 36.75rem;
        }

        &::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          // width: 100%;
          width: 980px;
          height: 100%;
          background: #52658d;
          opacity: 0.8;
          mix-blend-mode: darken;

          @media (max-width: $breakpoint-tablet) {
            max-width: 100%;
            height: 34.75rem;
          }
        }

        & img {
          // width: 100%;
          width: 980px;

          @media (max-width: $breakpoint-tablet) {
            max-width: 100%;
            height: 34.75rem;
          }
        }
      }

      & .center {
        position: absolute;
        bottom: 49px;
        width: 100%;
        padding: 0 3.9rem;

        @media (max-width: $breakpoint-large) {
          padding: 0 2.9rem;
        }
        @media (max-width: $breakpoint-tablet) {
          top: 5rem;
          bottom: unset;
        }

        & .content-center {
          max-width: var(--max-width);
          // border: 2px solid blue;
          // width: 80%;
          margin: 0 auto;
          display: flex;
          flex-direction: column;
          gap: 10px;

          & h2 {
            font-family: "Century Gothic";
            font-style: normal;
            font-weight: 700;
            font-size: 30px;
            line-height: 46px;
            display: flex;
            align-items: center;
            color: #ffffff;

            @media (max-width: $breakpoint-large) {
              font-size: 26px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 20px;
            }
          }

          & p {
            font-family: "Century Gothic";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 28px;
            display: flex;
            align-items: center;
            text-align: justify;
            color: #ffffff;
            max-width: 523px;

            @media (max-width: $breakpoint-large) {
              font-size: 12px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 10px;
            }
          }
        }
      }
    }
    & .right {
      position: absolute;
      // top: 157px;
      // bottom: 100%;
      // bottom: 0;
      top: 0;
      right: 0;
      border-radius: 134px 0px 0px 0px;
      width: 48%;
      //   height: 989px;
      max-width: 918px;
      // max-height: 989px;
      // border: 2px solid red;
      // width: 670px;
      // height: 800px;

      @media (max-width: $breakpoint-large) {
        width: 100%;
        // display: none;
        position: relative;
        height: fit-content;
      }
      @media (max-width: $breakpoint-tablet) {
        transform: translateY(-30px);
        height: 15rem;
      }

      & img {
        width: 100%;
        height: 968px;
        border-radius: 134px 0px 0px 0px;

        @media (max-width: $breakpoint-tablet) {
          width: 100%;
          height: 22.8125rem;
          border-radius: 134px 0px 0px 0px;
        }
      }
    }
  }
}

.about-section-two {
  position: relative;
  // border: 2px solid blue;

  @media (max-width: $breakpoint-large) {
    display: flex;
    flex-direction: column-reverse;
    gap: 20px;
  }
  @media (max-width: $breakpoint-tablet) {
    gap: 0px;
  }

  & .content {
    max-width: var(--max-width);
    margin: 0 auto 137px;
    padding-top: 44px;
    // border: 2px solid red;

    @media (max-width: $breakpoint-large) {
      padding: 0 2.5rem;
    }
    @media (max-width: $breakpoint-tablet) {
      padding-top: 94px;
    }

    & p {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      display: flex;
      align-items: center;
      text-align: justify;
      color: #52658d;
      max-width: 523px;

      @media (max-width: $breakpoint-large) {
        margin: 0 auto;
        width: fit-content;
        // border: 2px solid red;
      }
      @media (max-width: $breakpoint-tablet) {
      }
    }
  }
}

.about-section-three {
  width: 100%;
  position: relative;

  & .image {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      // width: 100%;
      // height: 100%;
      height: 388px;
      width: 100%;
      background: #ad832d;
      opacity: 0.7;
      z-index: 2;

      @media (max-width: $breakpoint-tablet) {
        height: 6.25rem;
      }
    }
    & img {
      object-fit: cover;
      height: 388px;
      width: 100%;

      @media (max-width: $breakpoint-tablet) {
        height: 6.25rem;
      }
    }
  }

  & .core-values {
    // position: relative;
    min-height: 900px;
    background: #f0f5ff;
    // opacity: 0.7;

    & .center {
      position: relative;
      width: 100%;
      // border: 2px solid blue;
      transform: translateY(-192px);
      z-index: 2;

      @media (max-width: $breakpoint-large) {
        transform: translateY(-82px);
      }
      @media (max-width: $breakpoint-tablet) {
      }

      & .content {
        // max-width: var(--max-width);
        padding: 0 50px;
        margin: 0 auto;
        // border: 2px solid red;
        display: flex;
        flex-direction: column;
        gap: 60px;
        align-items: center;
        @media (max-width: $breakpoint-large) {
          gap: 80px;
        }
        @media (max-width: $breakpoint-tablet) {
          gap: 90px;
        }

        & h1 {
          font-family: "Century Gothic";
          font-style: normal;
          font-weight: 600;
          font-size: 30px;
          line-height: 46px;
          color: #ffffff;

          @media (max-width: $breakpoint-large) {
            font-size: 28px;
          }
          @media (max-width: $breakpoint-tablet) {
            font-size: 25px;
          }
        }

        & .values {
          display: flex;
          // grid-template-columns: 1fr 1fr 1fr;
          flex-wrap: wrap;
          gap: 20px;
          justify-content: center;

          @media (max-width: $breakpoint-large) {
            width: 100%;
          }
          @media (max-width: $breakpoint-tablet) {
          }

          & .item {
            padding: 20px 45px;
            display: flex;
            flex-direction: column;
            gap: 24px;
            background: #ffffff;
            box-shadow: 0px 8px 4px rgba(189, 186, 186, 0.25);
            border-radius: 10px;
            max-width: 393px;
            min-height: 416px;

            @media (max-width: $breakpoint-tablet) {
              padding: 15px 25px;
            }

            & .heading {
              display: flex;
              gap: 20px;
              align-items: center;
              justify-content: center;

              & img {
                height: 28px;
                width: 28px;
              }
            }

            & h2 {
              font-family: "Century Gothic";
              font-style: normal;
              font-weight: 400;
              font-size: 22px;
              line-height: 32px;
              text-align: center;
              color: #ad832d;

              @media (max-width: $breakpoint-large) {
                font-size: 20px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 17px;
              }
            }

            & p {
              font-family: "Century Gothic";
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 28px;
              text-align: center;
              color: #000000;
              text-align: justify;

              @media (max-width: $breakpoint-large) {
                font-size: 13px;
              }
              @media (max-width: $breakpoint-tablet) {
                font-size: 11px;
              }
            }
          }
        }
      }
    }
  }
}

.about-section-four {
  position: relative;
  padding: 65px 0;

  & .content {
    max-width: var(--max-width);
    margin: 0 auto;
    // border: 2px solid blue;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;

    @media (max-width: $breakpoint-large) {
      // font-size: 17px;
      width: 80%;
    }
    @media (max-width: $breakpoint-tablet) {
      font-size: 17px;
    }

    & h1 {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 46px;
      text-align: center;
      color: #ad832d;
    }

    & h2 {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 700;
      font-size: 30px;
      line-height: 46px;
      text-align: center;
      color: #52658d;

      @media (max-width: $breakpoint-large) {
        font-size: 38px;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 25px;
      }
    }

    & p {
      font-family: "Century Gothic";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      text-align: center;
      color: #000000;

      @media (max-width: $breakpoint-large) {
        font-size: 17px;
        text-align: justify;
      }
      @media (max-width: $breakpoint-tablet) {
        font-size: 14px;
      }
    }

    & .members {
      // border: 2px solid red;
      display: flex;
      gap: 66px;
      margin-top: 50px;

      @media (max-width: $breakpoint-large) {
        flex-direction: column;
      }
      @media (max-width: $breakpoint-tablet) {
      }

      & .item {
        min-width: 369px;
        & .image {
          border-radius: 90px 0px 0px 0px;
          display: flex;
          justify-content: center;
        }

        & .text {
          background: #ffffff;
          box-shadow: 0px 8px 4px rgba(189, 186, 186, 0.25);
          border-radius: 0px 0px 90px 0px;
          padding: 48px 24px;
          cursor: pointer;

          & h3 {
            font-family: "Century Gothic";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #52658d;

            @media (max-width: $breakpoint-large) {
              font-size: 14px;
            }
            @media (max-width: $breakpoint-tablet) {
              font-size: 12px;
            }
          }
        }
      }
    }
  }

  & .details {
    position: fixed;
    z-index: 3;
    background: rgba(173, 131, 45, 0.8);
    // opacity: 0.8;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    // padding-bottom: 103px;
    align-items: center;

    & .center {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 60%;
      max-width: var(--max-width);
      background: #ffffff;
      border-radius: 10px;
      padding: 100px;
      align-items: center;
      opacity: 1;
      position: relative;
      // border: 2px solid red;
      max-height: 80%;
      // height: fit-content;
      margin-top: 80px;
      transition: var(--transition);

      @media (max-width: $breakpoint-large) {
        width: 90%;
        padding: 30px;
      }
      @media (max-height: 750px) {
        width: 90%;
        padding: 80px;
      }
      @media (max-height: 700px) {
        // width: 90%;
        height: 50%;
        padding: 50px;
        overflow: scroll;
      }
      @media (max-height: 650px) {
        overflow: scroll;
      }

      & h4 {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #ad832d;

        @media (max-width: $breakpoint-large) {
          font-size: 20px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 17px;
        }
        @media (max-height: 700px) {
          font-size: 15px;
        }
      }

      & p {
        font-family: "Century Gothic";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        text-align: center;
        color: #000000;

        @media (max-width: $breakpoint-large) {
          font-size: 12px;
        }
        @media (max-width: $breakpoint-tablet) {
          font-size: 11px;
        }
        @media (max-height: 700px) {
          font-size: 10px;
        }
      }

      & .close {
        position: absolute;
        top: 30px;
        right: 45px;
        cursor: pointer;

        @media (max-width: $breakpoint-tablet) {
          top: 20px;
          right: 20px;
        }
      }
    }
  }
}
